<template>
  <transition name="fade">
    <header name="isDarkBackground" :class="s.header">
      <a :class="s.header__title" href="/">
        <MittLogo 
          :color="isLightMode ? 'white' : '#1D1715' " 
          :customClass="s.MittLogo_in_header" />
        <span :class="[s.header__bar, isLightMode ? s.isLightMode : '']" /> 
        <span :class="[s.header__text, isLightMode ? s.isLightMode : '']">導入をご検討される事業者様へ</span>
      </a>
      <div :class="[s.header__menu, isDarkBackground ? s.isDarkBackground : '']">
        <div :class="s.info_container">
          <a :class="s.info_link_mitt" href="https://mitt.app/" target="_blank">
            <div :class="s.info_link_inner">
              <div :class="[s.info_link_text,s.info_border_color]">MITTサービスサイトへ</div>
              <span :class="[s.info_link_bar,s.bar_gray]" />
              <img :class="s.info_link_arrow" src="/img/partners/info_link_arrow.svg" alt="">
            </div>
          </a>
          <a :class="s.info_link" href="/form/" target="_blank">
            <div :class="s.info_link_inner">
              <div :class="s.info_link_text">資料・オンライン説明会の申込み</div>
              <span :class="[s.info_link_bar,s.bar_white]" />
              <img :class="s.info_link_arrow" src="/img/partners/info_link_arrow_white.svg" alt="">
            </div>
          </a>
        </div>
        <ul :class="[s.menuList, isScrollingDown ? s.isScrollingDown : '']">
          <li v-for="menu in menuItems" :key="menu.label" :class="s.menuItem">
            <a
              v-if="menu.link"
              :href="menu.link"
              @click.prevent="scrollToSection(menu.link)">
              {{ menu.label }}
            </a>
          </li>
        </ul>
      </div>
      <MenuDrawer :isOpen="isMenuOpen" :isLightMode="isLightMode" @toggle-menu="toggleMenu" />
    </header>
  </transition>
</template>

<script>
import MenuDrawer from '@/components/layouts/MenuDrawer.vue';
import { MittLogo } from '@/components/parts';
import { 
  ref, computed, onMounted, onBeforeUnmount,
} from 'vue';
import throttle from 'lodash/throttle';
import VueScrollTo from 'vue-scrollto';

export default {
  name: 'GlobalHeader',
  props: {
    isDarkBackground: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MenuDrawer,
    MittLogo,
  },
  setup(props) {
    const isMenuOpen = ref(false);
    const isScrollingDown = ref(true);
    const lastScrollY = ref(0);

    const handleScroll = throttle(() => {
      const currentScrollY = window.scrollY;
      isScrollingDown.value = currentScrollY > lastScrollY.value;
      lastScrollY.value = currentScrollY; 
    }, 100);
    
    const smoothScrollTo = (target) => {
      VueScrollTo.scrollTo(target, {
        offset: -130,
        duration: 1000,
        easing: 'ease-in-out',
      });
    };

    const scrollToSection = (id) => {
      if (window.location.pathname !== '/') {
        sessionStorage.setItem('scrollTarget', id);
        window.location.href = '/';
        return;
      }
      const selector = id.startsWith('#') ? id : `#${id}`;
      smoothScrollTo(selector);
    };

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);

      const scrollTarget = sessionStorage.getItem('scrollTarget');
      if (scrollTarget) {
        sessionStorage.removeItem('scrollTarget');
        setTimeout(() => {
          const selector = scrollTarget.startsWith('#') ? scrollTarget : `#${scrollTarget}`;
          smoothScrollTo(selector);
        }, 200);
      }
    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
    };
  
    const isLightMode = computed(() => props.isDarkBackground && !isMenuOpen.value); 

    const menuItems = [
      {
        label: '特徴',
        link: '#feature',
        isButton: false,
      },
      {
        label: 'ニュース',
        link: '#news',
        isButton: false,
      },
      {
        label: '導入事例',
        link: '#case_study',
        isButton: false,
      },
      {
        label: 'ソリューション',
        link: '#solution',
        isButton: false,
      },
      {
        label: 'パートナー',
        link: '#partner',
        isButton: false,
      },
      {
        label: '会社情報',
        link: '#company_infomation',
        isButton: false,
      },
    ];

    return {
      isMenuOpen,
      isScrollingDown,
      toggleMenu,   
      menuItems,
      isLightMode,
      scrollToSection,
    };
  },
  inheritAttrs: false,
};
</script>

<style lang="scss" module="s">
  :global(.fade-enter-active), :global(.fade-leave-active) {
  transition: transform 0.5s ease-in-out, opacity 0.5s;
  }

  :global(.fade-enter-from), :global(.fade-leave-to) {
    translate: 0 -5px;
    opacity: 0;
  }

  :global(.fade-enter-to), :global(.fade-leave-from) {
    translate: 0 0;
    opacity: 1;
  }

  .header {
    padding: 20px 20px 18px 40px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1300;
    display: flex;
    justify-content: space-between;
    width: 100vw;

    @include mdView {
      padding: 20px 0px 11px 20px;
    }
  } 

  .header__title {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 20px;

    @include mdView{
      margin-top: 0;
    }
  }
  
  .MittLogo_in_header {

    @include mdView{
      width: 58px;
    }
  }
  
  .header__bar {
    display: block;
    width: 2px;
    height: 20px;
    transition: background-color 0.3s ease-in-out;
    background-color: rgb(29 23 21 / 0.2);
    
    &.isLightMode {
      background-color: rgb(255 255 255 / 0.7);
    }

    @include mdView{
      display: none;
    }
  }

  .header__text {
    font-size: 16px;
    font-weight: normal;
    transition: color 0.3s ease-in-out;
    color: rgb(29 23 21 / 0.8);

    &.isLightMode {
      color: #FFFFFF;
    }

    @include mdView {
      display: none;
    }
  } 
  .header__menu{
    display: flex;
    flex-direction: column;
    gap: 26px;
    transition: opacity 0.3s ease-in-out;

    &.isDarkBackground{
      opacity: 0;
    }

    @include mdView {
      display: none;
    }
  }

  .info_container{
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
  
  .info_link {
    background-color: #1D1715;
    color: white;
    display: flex;
    gap: 12px;
    height: 45px;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    padding: 12px 12px 12px 16px;
  }

  .info_link_inner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: max((100% - 16px * 2),232px);
  }

  .info_link_text {
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;

    @include lgView{
      font-size: 12px;
    }
  }
  .info_link_bar {
    display: block;
    width: 1px;
    height: 20px;
  }

  .bar_gray {
    background-color:  rgba(0 0 0 / 0.1);
  }

  .bar_white {
    border-right: 1px solid white;
  }

  .info_link_arrow {
    width: 20px;
  }

  .info_link_mitt {
    color: #1D1715;
    border: 1px solid black;
    display: flex;
    gap: 12px;
    height: 44px;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    padding: 12px 12px 12px 16px;
    background-color: white;

    @include lgView{
      font-size: 12px;
    }
  }

  .menuList {
    display: flex;
    gap: 40px;
    pointer-events: auto;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;

    &.isScrollingDown {
      pointer-events: none;
      opacity: 0;
      translate: 0 -5px;
    }

    @include lgView{
      gap: 30px;
    }
  }

  .menuItem{
    font-size: 16px;
    font-weight: bold;

    @include lgView{
      font-size: 12px;
    }
  }


  @include mdView {
    .contents {
      height: var(--md-header-height);
      padding: 40px 20px 20px;
    }
  }
</style>
