<template>
  <svg :class="[s.mittLogo, customClass]" 
       fill="none" 
       height="68"
       width="74" 
       viewBox="0 0 74 68" 
       xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m70.2029 27.1453c2.5753-4.6221 4.3254-7.248 1.0337-10.0986-2.7838-2.41-4.2075-1.3309-4.2075-1.3309s3.2554-2.5809-2.2397-5.99806c-4.6609-2.8956-7.2272-.02697-7.2272-.02697s2.8655-3.48911-3.4639-6.21386c-6.5289-2.814673-9.6664 1.16004-9.6664 1.16004 2.3395-3.33624-6.8463-7.1401-12.9852-2.43699-9.0861 6.96024-11.0357 9.04654-11.0357 9.04654.1451-1.30394.1542-3.57006-8.3515-3.56107-6.26593.00899-16.50359 11.85217-9.52131 23.82127.41713.7104.87053 1.3849 1.35113 2.0144-.87959 1.4388-1.38739 3.1384-1.38739 4.9459 0 5.2786 4.31632 9.5681 9.64827 9.5681 3.5818 0 6.7012-1.9334 8.3606-4.8021 4.28 1.0252 7.5082 1.1331 7.5082 1.1331s12.55.8813 21.2824-1.8345c1.5415 3.2553 4.8694 5.5035 8.7324 5.5035 5.3228 0 9.6482-4.2805 9.6482-9.5681 0-2.1043-.6891-4.0467-1.8408-5.6204 1.8046-1.8165 3.2736-3.7409 4.3617-5.7013z" fill="#1b98d0"/>
    <path d="m62.5035 38.467c0 2.446-2.0041 4.4334-4.4705 4.4334-2.4665 0-4.4705-1.9874-4.4705-4.4334s2.004-4.4333 4.4705-4.4333c2.4664 0 4.4705 1.9873 4.4705 4.4333z" fill="#fff"/>
    <path d="m16.6206 38.467c0 2.446-2.004 4.4334-4.4704 4.4334-2.4665 0-4.47051-1.9874-4.47051-4.4334s2.00401-4.4333 4.47051-4.4333c2.4664 0 4.4704 1.9873 4.4704 4.4333z" fill="#fff"/>
    <g :class="s.logoText" 
       :style="{ fill: color }">
      <path d="m25.2432 52.1248h-6.1994c-.5787 0-1.1704.3656-1.4662.8951l-2.0836 3.656-.4887.8573c-.6817 1.1976-2.4052 1.1976-2.5209 0l-.0901-.8573-.373-3.656c-.0514-.5295-.4759-.8951-1.0546-.8951h-6.1994c-.73312 0-1.46625.5799-1.63345 1.2985l-3.0996878 13.2626c-.1672032.7186.2958248 1.2985 1.0289478 1.2985h2.86816c.73312 0 1.46625-.5799 1.63345-1.3111l1.53056-6.6439.45015-2.0424.21864 2.0424.8103 7.0725c.06431.5295.48876.8825 1.05467.8825h3.11256c.5659 0 1.1575-.353 1.4662-.8825l4.1158-7.0725 1.1704-2.0424-.5016 2.0424-1.5691 6.6439c-.1672.7186.2829 1.3111 1.0289 1.3111h3.074c.7331 0 1.4662-.5799 1.6334-1.2985l3.0997-13.2626c.1672-.7186-.2958-1.2985-1.029-1.2985"/>
      <path d="m51.3789 51.9735h-13.8907c-.5402 0-1.0804.4287-1.209.9582l-.0772.353-.2572 1.0842c-.1286.5295.2186.9581.7588.9581h3.7042l-1.0546 4.5007-.0258.1261-1.0161 4.3242c-.5787 2.471.5531 3.6939 3.6399 3.6939h5.0804c.7332 0 1.4663-.58 1.6335-1.2986l.2058-.9077c.1672-.7186-.2959-1.2985-1.029-1.2985h-1.4405c-1.1833 0-1.5948-.3152-1.4019-1.1346l.7845-3.3787.0258-.1261 1.0546-4.5007h3.7042c.193 0 .3859-.0504.5531-.1513.3215-.1765.5659-.4664.6559-.8194l.2573-1.0842.0771-.353c.1287-.5295-.2186-.9582-.7588-.9582"/>
      <path d="m68.2411 51.9734h-13.8907c-.5402 0-1.0804.4286-1.209.9581l-.0772.353-.2572 1.0842c-.0772.3404.0386.643.2701.8195.1286.0882.2958.1513.4888.1513h3.7041l-.7459 3.1769-.3087 1.3238-.0257.1261-1.0161 4.3242c-.5788 2.471.553 3.6938 3.6399 3.6938h5.0804c.7331 0 1.4662-.5799 1.6334-1.2985l.2058-.9077c.1672-.7186-.2958-1.2985-1.029-1.2985h-1.4405c-1.1833 0-1.5948-.3152-1.4019-1.1347l.7846-3.3786.0257-.1261 1.0547-4.5007h3.7041c.193 0 .3859-.0505.5531-.1513.3215-.1765.5659-.4665.6559-.8195l.2573-1.0842.0772-.353c.1286-.5295-.2187-.9581-.7589-.9581"/>
      <path d="m32.2004 51.4565c-1.8521 0-3.7042 1.475-4.1286 3.2904-.1672.7438-.0772 1.412.2187 1.9667l1.0418 2.9879c-1.3634.1008-2.4181.416-2.5081.7942l-1.5434 6.6313c-.1029.4665 1.2733.8447 3.0868.8447s3.3698-.3782 3.4856-.8447l1.5434-6.6313c.09-.3782-.8232-.6934-2.1351-.7942l2.4438-3.0005h-.0129c.553-.5421.9646-1.2229 1.1318-1.9667.4245-1.8154-.7331-3.2905-2.5981-3.2905m-.4372 1.7902c.8617 0 1.4019.6808 1.1961 1.5255-.1929.8447-1.0547 1.5255-1.9164 1.5255s-1.4019-.6808-1.1962-1.5255c.2058-.8447 1.0547-1.5255 1.9165-1.5255z"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#1D1715', // デフォルトの文字色を黒にする
    },
    customClass: {
      type: String,
      default: '',
    },
  },
};
</script>


<style lang="scss" module="s">
  .mittLogo {
    display: block;
    aspect-ratio: 74 / 68;
    position: relative;
    z-index: 1200;
  }

  .logoText{
    transition: color 0.3s ease-in-out;
  }
</style>
