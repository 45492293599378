<template>
  <GlobalHeader />
  <template v-if="!flag.notFound">
    <div :class="s.container">
      <div :class="s.section_header">
        <div :class="s.section_title">NEWS</div>
        <div :class="s.section_sub_title">MITTの導入事例</div>
      </div>
    </div>
    <div :class="s.section_bar" />
    <div :class="s.articleContainer">
      <div :class="s.articleHeader">
        <h1 :class="s.articleHeader__title">{{news.title}}</h1>
        <div :class="s.articleMeta">
          <div :class="s.articleMeta__date">{{news.date}}</div>
          <div :class="s.articleMeta__bar" />
          <div :class="s.articleMeta__category">{{news.category}}</div>
        </div>
      </div>
      <div :class="s.articleThumb">
        <img :class="s.articleThumb__img" :src="`/img/news/news-${news.id}/thumb.jpg`" :alt="news.title">
      </div>
      <div :class="[s.articleContent, 'articleContent']" v-html="news.content" />
    </div>
  </template>
  <div :class="s.footer_wrap">
    <div :class="s.footer_container">
      <div :class="s.message_title">
        <div :class="s.message_title_small">いまも未来も、</div>
        <div :class="s.message_title_lg">交通空白地「ゼロ」</div>
      </div>
      <div :class="s.footer_content">
        <div :class="s.footer_content_item">© 2024 MITT</div>
        <img src="/img/partners/footer_mitt_logo.svg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import newsList from '@/data/newsList.yaml';
import GlobalHeader from '@/components/layouts/GlobalHeader.vue';

export default {
  name: 'NewsDetail',
  data() {
    return {
      news: null,
      id: null,
      flag: {
        notFound: false,
      },
    };
  },
  created() {
    if (!this.$route.params.id) {
      this.flag.notFound = true;
      return;
    }
    this.id = Number.parseInt(this.$route.params.id, 10);
    this.getNewsDetail();
  },
  mounted() {
    setTimeout(() => { this.anchor(); }, 200);
  },
  methods: {
    /**
     * 実際にはAPIで取得
     */
    getNewsDetail() {
      const ids = newsList.map((news) => news.id);
      if (!ids.includes(this.id)) { this.flag.notFound = true; return; }
      this.news = newsList.filter((news) => this.id === news.id)[0];
    },

    anchor() {
      const hash = this.$route.hash;
      if (hash && hash.match(/^#.+$/)) {
        this.$scrollTo(hash);
      }
    },
  },
  components: {
    GlobalHeader, // コンポーネントとして登録
  },
};
</script>

<style lang="scss" module="s">
  .container {
    margin: auto;
    margin-top: 148px;
    width: min(100% - 80px * 2, 1280px);

    @include mdView {
      width: min(100% - 50px * 2, 1280px);;
    }

    @include smView {
      width: max(100% - 20px * 2, 350px);
    }
  }

  .section_header {
    width: 100%;
    padding-bottom: 72px;

    @include smView {
      padding-bottom: 40px;
    }
  }

  .section_title {
    font-size: 88px;
    font-weight: bold;
    margin-bottom: 29px;
    line-height: 1;
    @include smView {
      font-size: 64px;
      margin-bottom: 24px;
      overflow-wrap: break-word;
      width: 100%;
    }
  }
  .section_sub_title {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: 8px;
    &::before {
      content: '';
      width: 8px;
      height: 8px;
      background-color: rgb(27, 152, 208,);
      border-radius: 50%;
    }
  }
  .section_caption {
    font-size: 75px;
    font-weight: bold;
    padding-top: 48px;

    @include lgView {
      font-size: 65px;
    }

    @include mdView {
      font-size: 55px;
    }

    @include smView {
      font-size: 40px;
      padding-top: 40px;
    }
  }

  .section_bar {
    height: 1px;
    width: 100%;
    background-color: rgb(0 0 0 / 0.2);
  }

  .articleContainer {
    margin: auto;
    width: min(100% - 80px * 2, 800px);

    @include mdView {
      width: min(100% - 50px * 2, 800px);
    }

    @include smView {
      width: max(100% - 20px * 2, 350px);
    }
  }

  .articleHeader {
    margin-top: 40px;
  }

  .articleHeader__title {
    font-size: 40px;

    @include mdView {
      font-size: 32px;
    }
  }

  .articleMeta {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-top: 40px;
    font-size: 14px;
  }

  .articleMeta__bar {
    width: 1px;
    height: 16px;
    background-color: #000;
  }

  .articleThumb {
    margin-top: 40px;
  }

  .message_title {
    color: white;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    letter-spacing: 2px;
    @include smView {
      letter-spacing: 0;
    }
  }

  .message_title_small {
    font-size: 76px;
    margin-bottom: 6px;
    @include mdView {
      font-size: 56px;
    }
    @include smView {
      font-size: 46px;
      margin-bottom: 8px;
    }
  }

  .message_title_lg {
    display: flex;
    flex-wrap: wrap;
    font-size: 90px;
    margin-bottom: 32px;

    @include mdView {
      font-size: 70px;
    }

    @include smView {
      font-size: 52px;
      margin-bottom: 18px;
    }
  }

  .footer_wrap {
    margin-top: 200px;
    width: 100%;
    background-image: url(/img/partners/footer_img.jpg);
    height: 709px;
    background-size: cover;
    background-position: center;
    display: flex;

    @include smView {
     height: 440px;
     margin-top: 120px;
    }
  }
  .footer_container {
    position: relative;
    padding-top: 10px;
    color: white;
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: clamp(66.6666666667%, 79%, 960px);
    @include smView {
      height: 440px;
      width: min(100% - 30px * 2, 326px);
    }
  }

  .footer_content {
    position: absolute;
    display: flex;
    justify-content: space-between;
    bottom: 64px;
    position: absolute;
    display: flex;
    justify-content: space-between;
    bottom: 64px;
    width: min(100%, 960px);
    @include smView {
      bottom: 32px;
    }
  }
  .footer_content_item {
    font-size: 16px;
    font-weight: bold;
  }
</style>

<style lang="scss">
.articleContent {

  .title {
    margin-top: 40px;
    margin-bottom: 16px;
    font-size: 28px;
    font-weight: bold;
  }

  p {
    margin-top: 16px;
    font-size: 18px;
    line-height: 2;
    letter-spacing: 0.03;
  }

  .imageContainer {
    width: min(100%, 560px);
    margin-top: 40px;
    margin-inline: auto;

    & + .title {
      margin-top: 80px;
    }
  }

  img {
    border-radius: 4px;
    border: 1px solid rgb(0 0 0 /0.1);
  }

  .caption {
    margin-top: 8px;
    font-size: 12px;
    color: rgb(0 0 0 /0.64);
  }

  .listContainer {
    font-size: 18px;
    > li {
      display: flex;
      &:not(:first-child) {
        margin-top: 16px;
      }
      .listNumber {
        width: 50px;
        flex-shrink: 0;
      }
      .listContentTitle {
        font-weight: bold;
        margin-bottom: 16px;
      }
    }
  }

  .imageFlexContainer {
    display: flex;
    > img {
      margin-top: 24px;
      margin-right: 16px;
      width: 25%;
      min-width: 150px;
      height: fit-content;
      flex-shrink: 0;
    }
  }

  .notes {
    margin-top: 40px;
    > p {
      font-size: 14px;
      margin-top: 12px;
      line-height: 1.6;
      padding-left: 16px;
      position: relative;
      &::after {
        content: '';
        width: 6px; height: 6px;
        border-radius: 6px;
        position: absolute;
        top: 8px; left: 0;
        background-color: var(--label-primary);
      }
    }
  }

  .fw-bold {
    font-weight: bold;
  }
  .text-underline {
    text-decoration: underline;
  }
}
</style>
