<template>
  <div :class="s.wrapper">
    <!-- menu items -->
    <div :class="[s.content, isOpen ? s.isOpen : '']">
      <ul :class="s.menuList">
        <li v-for="menu in menuItems" :key="menu.label" :class="s.menuItem">
          <a
            v-if="menu.link"
            href="javascript:void(0);"
            @click="handleClick(menu.link)">
            {{ menu.label }}
          </a>
          <a
            v-if="menu.externalLink"
            :href="menu.externalLink"
            target="_blank"
            @click="$emit('toggle-menu')">
            {{ menu.label }}
          </a>
        </li>    
      </ul>
      <div :class="s.info_container">
        <a :class="s.info_link" href="/form/" target="_blank">
          <div :class="s.info_link_inner">
            <div :class="s.info_link_text">資料・オンライン説明会の申込み</div>
            <span :class="[s.info_link_bar,s.bar_white]" />
            <img :class="s.info_link_arrow" src="/img/partners/info_link_arrow_white.svg" alt="">
          </div>
        </a>
        <a :class="s.info_link_mitt" href="https://mitt.app/" target="_blank">
          <div :class="s.info_link_inner">
            <div :class="[s.info_link_text,s.info_border_color]">MITTサービスサイトへ</div>
            <span :class="[s.info_link_bar,s.bar_gray]" />
            <img :class="s.info_link_arrow" src="/img/partners/info_link_arrow.svg" alt="">
          </div>
        </a>
      </div>
    </div>
    <!-- button -->
    <button
      :class="[s.button, isOpen ? s.isOpen : '']" 
      @click="$emit('toggle-menu')"
    >
      <span :class="buttonBar"></span>
      <span :class="buttonBar"></span>
    </button>
  </div>
</template>

<script>
import { computed, useCssModule, onMounted } from 'vue';
import VueScrollTo from 'vue-scrollto';

export default {
  props: {
    isOpen: Boolean, 
    isLightMode: Boolean,
  },
  emits: ['toggle-menu', 'scroll-to'],
  setup(props, { emit }) {
    const s = useCssModule('s'); 

    const buttonBar = computed(() => {
      const classes = [s.bar];
      if (props.isOpen) {
        classes.push(s.isOpen);
        return classes;
      } 
      if (props.isLightMode) {
        classes.push(s.isLightMode);
      }
      return classes;   
    });

    const smoothScrollTo = (target) => {
      VueScrollTo.scrollTo(target, {
        offset: -130,
        duration: 1000,
        easing: 'ease-in-out',
      });
    };

    const scrollToSection = (id) => {
      if (window.location.pathname !== '/') {
        sessionStorage.setItem('scrollTarget', id);
        window.location.href = '/';
        return;
      }
      const selector = id.startsWith('#') ? id : `#${id}`;
      smoothScrollTo(selector);
    };

    const handleClick = (link) => {
      if (!link.startsWith('#')) {
        window.location.href = link;
        return; 
      }
      const sectionId = link.substring(1);
      scrollToSection(sectionId);
      emit('toggle-menu'); 
    };

    onMounted(() => {
      const scrollTarget = sessionStorage.getItem('scrollTarget');
      if (scrollTarget) {
        sessionStorage.removeItem('scrollTarget');
        setTimeout(() => {
          const selector = scrollTarget.startsWith('#') ? scrollTarget : `#${scrollTarget}`;
          smoothScrollTo(selector);
        }, 200);
      }
    });

    const menuItems = [
      {
        label: '特徴',
        link: '#feature',
        isButton: false,
      },
      {
        label: 'ニュース',
        link: '#news',
        isButton: false,
      },
      {
        label: '導入事例',
        link: '#case_study',
        isButton: false,
      },
      {
        label: 'ソリューション',
        link: '#solution',
        isButton: false,
      },
      {
        label: 'パートナー',
        link: '#partner',
        isButton: false,
      },
      {
        label: '会社情報',
        link: '#company_infomation',
        isButton: false,
      },
    ];

    return {
      menuItems,
      buttonBar,
      handleClick,
      scrollToSection,
    };
  },
};
</script>

<style lang="scss" module="s">
.wrapper {
  display: none;
  position: relative;
  bottom: 100%;
  left: 0;
  @include mdView {
    display: block;
  } 
}

.button_container{
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.button {
  position: relative;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  z-index: 1200;
}

.bar {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 24px;
  height: 6px;
  background-color: #1D1715;
  border-radius: 12px;
  transform-origin: center;
  rotate: 0deg;
  transition: all .3s ease-in-out;

  &.isLightMode {
    background-color: white;
  }
  
  &:nth-child(1) {
    translate: 0 -5px;

    &.isOpen {
      translate: 0 0;
      rotate: 225deg;
    }
  }

  &:nth-child(2) {
    translate: 0 5px;

    &.isOpen {
      translate: 0 0;
      rotate: -225deg;
    }
  }
}

.content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  padding: var(--md-header-height) 20px 80px;
  transition: all .5s ease-in-out;
  translate: 0 -100%;
  pointer-events: none;
  z-index: 1000;
  overflow-y: auto;
  overscroll-behavior: contain;

  &.isOpen {
    translate: 0 0;
    pointer-events: auto;
  }
}

.menuList {
  padding: 60px 0 60px 44px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.menuItem {
  font-size: 18px;
  font-weight: bold;
}

.info_container{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.info_link {
  background-color: black;
  color: white;
  display: flex;
  gap: 12px;
  height: 57px;
  align-items: center;
  border-radius: 4px;
  justify-content: center;
  width: 100%;
}

.info_link_inner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: min((100% - 16px * 2),255px);
}

.info_link_text {
  font-weight: bold;
  font-size: 14px;
}

.info_link_bar {
  display: block;
  width: 1px;
  height: 20px;
}

.bar_gray {
  background-color:  rgba(0 0 0 / 0.1);
}

.bar_white {
  border-right: 1px solid white;
}

.info_link_arrow {
  width: 20px;
}

.info_link_mitt {
  color: black;
  border: 1px solid black;
  display: flex;
  gap: 12px;
  height: 54px;
  align-items: center;
  border-radius: 4px;
  justify-content: center;
  width: 100%;
}
</style>
